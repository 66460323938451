@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  padding: 2rem;
}

.Button {
  cursor: pointer;
}
.Button--reversed-active {
  color: white;
}
.Button--reversed-inactive {
  color: #aaa;
}

.Button--normal-active {
  color: black;
}
.Button--normal-inactive {
  color: #ccc;
}

.Icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.Menu > * {
  display: inline-block;
}
.Menu > * + * {
  margin-left: 15px;
}

.Toolbar {
  position: relative;
  padding: 1px 18px 17px;
  margin: 0 -20px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}

.PointRef {
  padding: 0.1em 0.5em;
  margin: 0em 0.2em;
  border-radius: 0.2em;
  display: inline-block;
  background-color: #efefef;
  transition: background 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.PointRef--focused {
  background-color: #ddd;
}

.PointRef--selected {
  box-shadow: 0 0 0 3px #b4d5ff;
  background-color: cyan;
}

.PointRef__id {
  font-size: 0.85em;
}

.PointRef__attr,
.PointRef__type {
  font-weight: 700;
}

.FormulaEditor__debug {
  display: flex;
}
.FormulaEditor__debug > * {
  padding: 1rem;
  margin-right: 2rem;
  background-color: #ddd;
}

svg {
  background-color: #eee;
}

